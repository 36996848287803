export enum AdminViewType {
    Organization = 1,
    Company,
    Department,
    Device,
    Forklift,
    User,
    Operator,
    Tag,
    Battery
}